<template>
  <div>
    <BaseModal
      ref="modal"
      title="Overzicht tags"
      max-width="tw-max-w-3xl"
    >
      <div
        v-for="category in categoriesToRender"
        :key="category.id"
        class="tw-p-4 tw-rounded tw-shadow-lg tw-mb-4"
      >
        <h1 class="tw-text-xl">
          {{ category.category }}
        </h1>
        <span
          v-if="!category.tags.length"
          class="tw-italic"
        >
          Geen tags beschikbaar
        </span>
        <div v-else class="tw-flex tw-flex-wrap tw-gap-1">
          <button
            v-for="tag in category.tags"
            :key="tag.id"
            type="button"
            :style="`
              background: ${category.hex_value};
              color: ${getTagTextColor(category.hex_value)};
            `"
            class="tag tw-block"
            @click="select(tag)"
          >
            {{ tag.name }}
          </button>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { getContactTagsOverview } from '@/services/contacts'
import { getTagTextColor } from '@/utils/helpers'
export default {
  name: 'ContactTagsOverview',

  props: {
    excludeAutomatedTags: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      include: false,
      categories: []
    }
  },

  computed: {
    categoriesToRender () {
      // `Reserve` are reserved categories for future usage
      // To do: fix this in the BE (by adding an 'is_active` field on Database level)
      return this.categories?.filter(cat => { return cat.category !== 'Reserve' })
    }
  },

  methods: {
    getTagTextColor,
    async show () {
      const params = {}
      if (this.excludeAutomatedTags) {
        params.allow_manual_assignment = 1
      }
      const response = await getContactTagsOverview({ params })
      this.categories = response.data
      this.$refs.modal.show()
      return response
    },
    setInclude (value) {
      this.include = value
    },
    select (tag) {
      this.$emit('tagSelected', { tag, include: this.include })
    }
  }
}
</script>
